<template>
  <div>
    <b-modal id="modal-center-add-domain" centered hide-footer hide-header>
      <create-project />
    </b-modal>

    <div class="page-wrapper-md us-0">
      <div class="d-flex align-items-center mb-4">
        <h2 class="fw-600 text-darkgreen">
          {{ Welcome | multilingual }} {{ name }}
        </h2>
        <div class="ms-auto" v-if="ai_Automation_count != 0 || Ai_ContentGenerator_count != 0">
          <!-- <button v-if="permissions.includes('nexrank-client.store')" class="btn btn-primary d-flex align-items-center" v-b-modal.modal-center-add-domain>
            <span class="material-icons-outlined"> add </span>
            <span class="ms-2 fw-600" style="padding-bottom: 2px">{{ Create_Project }}</span>
          </button> -->
        </div>
      </div>
      <!-- v-if="ai_Automation_count == 0 || ai_Automation_count == null || Ai_ContentGenerator_count == 0 || Ai_ContentGenerator_count == null" -->
      <div class="nexcard grey-bg py-3">
        <div class="nexcard-heading d-flex">
          <h4 class="text-muted">{{ Explore_with_Nexmind | multilingual }}</h4>
          <!-- <button type="button" class="btn btn-tooltip" data-toggle="tooltip" data-placement="bottom" title="Tooltip on right">
            <span class="material-icons-outlined">help_outline</span>
          </button> -->
        </div>
        <div class="nexcard-body pt-2 pb-2">
          <div class="row">
            <div class="col-12 col-md-6 col-xl-3 pb-4 pb-xl-0">
              <div class="d-flex flex-column justify-content-center align-items-center nexcard p-4 no-bs h-100">
                <span class="material-icons-outlined">play_circle_outline</span>
                <span class="text-muted fw-600 my-2 text-center">{{
                  Complete_Tutorial | multilingual
                }}</span>
                <button @click="watchTutorial()" class="btn btn-primary mt-auto w-100">
                  {{ Watch | multilingual }}
                </button>
              </div>
            </div>

            <div class="col-12 col-md-6 col-xl-3 pb-4 pb-xl-0">
              <div class="d-flex flex-column justify-content-center align-items-center nexcard p-4 no-bs h-100">
                <span class="material-icons-outlined">create_new_folder</span>
                <span class="text-muted fw-600 my-2 text-center">{{
                  Create_your_First_Project | multilingual
                }}</span>
                <router-link v-if="
                  project_count.length == 0 || project_count.length == null
                " class="router__link_dashboard btn btn-primary mt-auto w-100" :to="{
                    name: 'create_project',
                  }">{{ Start | multilingual }}
                </router-link>

                <div class="d-flex align-items-center justify-content-center mt-auto" v-else>
                  <span class="material-icons-outlined text-green mt-auto">
                    task_alt
                  </span>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6 col-xl-3 pb-4 pb-md-0" v-if="
              permissions.includes('Seo-Automation.index') && seo_quota > 0
            ">
              <div class="d-flex flex-column justify-content-center align-items-center nexcard p-4 no-bs h-100">
                <span class="material-icons-outlined">psychology</span>
                <span class="text-muted fw-600 text-center my-2">{{
                  Start_Your_First_Automation | multilingual
                }}</span>
                <router-link v-if="ai_Automation_count == 0 || ai_Automation_count == null"
                  class="router__link_dashboard btn btn-primary mt-auto w-100" :to="{
                    name: 'AI SEO Automation_Add Keyword',
                    params: { step: 'topic_details' },
                  }">{{ Start | multilingual }}
                </router-link>

                <div class="d-flex align-items-center justify-content-center mt-auto" v-else>
                  <span class="material-icons-outlined text-green mt-auto">
                    task_alt
                  </span>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6 col-xl-3" v-if="
              permissions.includes('Nexwriter.index') &&
              content_quota > 0 &&
              email != 'maxis.grow@gmail.com'
            ">
              <div class="d-flex flex-column justify-content-center align-items-center nexcard p-4 no-bs h-100">
                <span class="material-icons-outlined">drive_file_rename_outline</span>
                <span class="text-muted fw-600 text-center my-2">{{
                  Create_Your_First_Content | multilingual
                }}</span>
                <router-link v-if="
                  Ai_ContentGenerator_count == 0 ||
                  Ai_ContentGenerator_count == null
                " class="router__link_dashboard btn btn-primary mt-auto w-100" :to="{
                    name: 'content_image_add_fields',
                    params: {
                      category: 'blog writing',
                      step: 'topic_details',
                    },
                  }">{{ Start | multilingual }}
                </router-link>

                <div class="d-flex align-items-center justify-content-center mt-auto" v-else>
                  <span class="material-icons-outlined text-green mt-auto">
                    task_alt
                  </span>
                </div>
              </div>
            </div>

            <!-- <div class="col-12 col-md-6 col-xl-3" v-if="permissions.includes('Nexwriter.index') && content_quota > 0">
              <div class="d-flex flex-column justify-content-center ps-md-2">
                <div class="d-flex align-items-center justify-content-center">
                  <span class="material-icons-outlined">drive_file_rename_outline</span>

                  <span class="row-text ms-2">Create Your First Content</span>
                </div>
                <router-link
                  v-if="Ai_ContentGenerator_count == 0 || Ai_ContentGenerator_count == null"
                  class="router__link_dashboard btn btn-primary mt-3"
                  style="width: 150px; margin: 1rem auto 0"
                  :to="{
                    name: 'content_image_add_fields',
                    params: {
                      category: 'blog writing',
                      step: 'topic_details',
                    },
                  }"
                  >Start
                </router-link>

                <div class="d-flex align-items-center justify-content-center mt-3" v-else>
                  <span class="material-icons-outlined text-green m-auto"> task_alt </span>
                </div>
              </div>
            </div> -->

            <!-- <div v-if="email == 'maxis.grow@gmail.com'" class="px-3">
              <hr class="hr-line" />
            </div> -->

            <div class="col-12 col-md-6 col-xl-3" v-if="email == 'maxis.grow@gmail.com'">
              <div class="d-flex flex-column justify-content-center align-items-center nexcard p-4 no-bs h-100">
                <span class="material-icons-outlined">note_add</span>
                <span class="text-muted fw-600 text-center my-2">{{
                  Add_Your_First_Page
                }}</span>
                <router-link v-if="enterprise_count == 0 || enterprise_count == null"
                  class="router__link_dashboard btn btn-primary mt-auto w-150px" :to="{
                    name: 'Enterprise SEO_Add Keyword',
                  }">{{ Start | multilingual }}
                </router-link>

                <div class="d-flex align-items-center justify-content-center mt-auto" v-else>
                  <span class="material-icons-outlined text-green mt-auto">
                    task_alt
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-2">
              <div
                class="d-flex flex-column"
                style="
                  place-items: center;
                  border-radius: 50%;
                  padding: 0rem 0rem 1rem 0rem;
                  margin-top: -1rem;
                "
              >
                <span class="material-icons-outlined mt-3">thumb_up</span>
                <h5 class="mt-3">Well Done</h5>
              </div>
            </div> -->
        </div>
      </div>

      <div class="nexcard my-5 us-0">
        <div class="nexcard-heading d-flex align-items-center">
          <h4 class="text-muted">
            {{ What_would_you_like_to_do_today | multilingual }}
          </h4>
          <!-- <button type="button" class="btn btn-tooltip" data-toggle="tooltip" data-placement="bottom" title="Tooltip on right">
            <span class="material-icons-outlined">help_outline</span>
          </button> -->

          <!-- <div class="ms-auto">
            <button @click="viewWidget()">
              <span
                id="hoverBtnDashboard1"
                class="material-icons-outlined btn-editDashboard"
                >widgets</span
              >
              <b-popover
                :target="'hoverBtnDashboard1'"
                triggers="hover"
                placement="left"
                custom-class="popover"
                ><template>Add Favourite Features here!</template></b-popover>
</button>
</div> -->
        </div>

        <div class="nexcard-body">
          <!-- AI SEO -->
          <div class="mt-4" v-if="show_seo_categories.length != 0">
            <h6 class="fw-bold text-darkgrey" v-if="showAiSeo > 0">
              {{ AI_SEO_1_credit_per_Campaign | multilingual }}
            </h6>

            <div class="dashboard__apps">
              <div class="row py-2">
                <div v-show="permissions.includes(n.permission) && n.quota > 0" class="col-size"
                  v-for="(n, index) in show_seo_categories" :key="'seo_caegories' + index">
                  <div @click="OpenPage(n.title)" class="dashboard__app d-flex flex-column justify-content-center">
                    <!-- <span class="material-icons-outlined">screen_search_desktop</span> -->
                    <img :src="require(`@/assets/icons/${n.icon}.svg`)" alt="" class="cardIcon__icon" />
                    <span class="text">{{ n.title | multilingual }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Blog Writing -->
          <div v-show="permissions.includes('Nexwriter.index') && content_quota > 0
            " class="mt-4" v-if="show_Cases_blogwriting.length != 0">
            <h6 class="fw-bold text-darkgrey">
              {{ Blog_Writing_4_credits | multilingual }}
            </h6>

            <div class="dashboard__apps">
              <div class="row py-2">
                <div class="col-size" v-for="(n, index) in show_Cases_blogwriting" :key="'Cases_blogwriting' + index">
                  <div @click="addContent(n.title)" class="dashboard__app d-flex flex-column justify-content-center">
                    <!-- <span class="material-icons-outlined">description</span> -->
                    <img src="@/assets/icons/icon-blog-writing.svg" alt="" />
                    <span class="text">{{
                      n.title | multilingual | capitalize
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Freestyle -->
          <div v-show="permissions.includes('Nexwriter.index') && content_quota > 0
            " class="mt-4" v-if="show_Cases_long.length != 0">
            <h6 class="fw-bold text-darkgrey">
              {{ Freestyle_4_credits | multilingual }}
            </h6>

            <div class="dashboard__apps">
              <div class="row py-2">
                <div class="col-size" v-for="(n, index) in show_Cases_long" :key="'Cases_long' + index">
                  <div @click="addContent(n.title)" class="dashboard__app d-flex flex-column justify-content-center">
                    <!-- <span class="material-icons-outlined">description</span> -->
                    <img :src="require(`@/assets/icons/${n.icon}.svg`)" alt="" class="cardIcon__icon" />

                    <span v-if="n.title == 'keyword cluster'" class="text">{{
                      "keyword clusters" | multilingual | capitalize
                    }}</span>
                    <span v-else class="text">{{
                      n.title | multilingual | capitalize
                    }}</span>
                  </div>
                </div>

                <!-- <div class="col-size" v-if="show_Cases_long.length < 5" @click="choose_category('Freestyle')">
                  <div class="dashboard__app d-flex flex-column justify-content-center">
                    
                    <img src="@/assets/icons/icon-view-list.svg" alt="" />
                    <span class="text">{{ View_Others | multilingual }}</span>
                  </div>
                </div> -->
              </div>
            </div>
          </div>

          <!-- Advertisement -->
          <div v-show="permissions.includes('Nexwriter.index') && content_quota > 0
            " class="mt-4" v-if="show_Cases_short_Adv.length != 0">
            <h6 class="fw-bold text-darkgrey">
              {{ Advertisement_1_credit | multilingual }}
            </h6>
            <div class="dashboard__apps">
              <div class="row py-2">
                <div class="col-size" v-for="(n, index) in show_Cases_short_Adv" :key="'Cases_short_Adv' + index">
                  <div @click="addContent(n.title)" class="dashboard__app d-flex flex-column justify-content-center">
                    <img :src="require(`@/assets/icons/${n.icon}.svg`)" alt="" class="cardIcon__icon" />

                    <span v-if="n.title == 'google ad title'" class="text">{{
                      "google ads title" | multilingual | capitalize
                    }}</span>
                    <span v-else-if="n.title == 'google ad descriptions'" class="text">{{
                      "google ads descriptions" | multilingual | capitalize
                    }}</span>
                    <span v-else class="text">{{
                      n.title | multilingual | capitalize
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Post and Captions Idea -->
          <div v-show="permissions.includes('Nexwriter.index') && content_quota > 0
            " class="mt-4" v-if="show_Cases_short_Post.length != 0">
            <h6 class="fw-bold text-darkgrey">
              {{ Post_and_captions_idea_1_credit | multilingual }}
            </h6>
            <div class="dashboard__apps">
              <div class="row py-2">
                <div class="col-size" v-for="(n, index) in show_Cases_short_Post" :key="'Cases_short_Post' + index">
                  <div @click="addContent(n.title)" class="dashboard__app d-flex flex-column justify-content-center">
                    <img :src="require(`@/assets/icons/${n.icon}.svg`)" alt="" class="cardIcon__icon" />
                    <span class="text">{{
                      n.title | multilingual | capitalize
                    }}</span>
                  </div>
                </div>

                <!-- <div class="col-size">
                  <div v-if="show_Cases_short_Post.length < 5" @click="choose_category('Post and Captions Idea')"
                    class="dashboard__app d-flex flex-column justify-content-center">
                    
                    <img src="@/assets/icons/icon-view-list.svg" alt="" />
                    <span class="text">{{ View_Others | multilingual }}</span>
                  </div>
                </div> -->
              </div>
            </div>
          </div>

          <!-- E-commerce -->
          <div v-show="permissions.includes('Nexwriter.index') && content_quota > 0
            " class="mt-4" v-if="show_Cases_short_Prod.length != 0">
            <h6 class="fw-bold text-darkgrey">
              {{ E_commerce_1_credit | multilingual }}
            </h6>
            <div class="dashboard__apps">
              <div class="row py-2">
                <div class="col-size" v-for="(n, index) in show_Cases_short_Prod" :key="'Cases_short_Prod' + index">
                  <div @click="addContent(n.title)" class="dashboard__app d-flex flex-column justify-content-center">
                    <img :src="require(`@/assets/icons/${n.icon}.svg`)" alt="" class="cardIcon__icon" />
                    <span class="text">{{
                      n.title | multilingual | capitalize
                    }}</span>
                  </div>
                </div>

                <div class="col-size">
                  <div v-if="show_Cases_short_Prod.length < 3" @click="choose_category('E-commerce')"
                    class="dashboard__app d-flex flex-column justify-content-center">
                    <!-- <span class="material-icons-outlined grey">east</span> -->
                    <img src="@/assets/icons/icon-view-list.svg" alt="" />
                    <span class="text">{{ View_Others | multilingual }}</span>
                  </div>
                </div>
                <div class="col-size"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="nexcard blue-bg mb-5 d-flex flex-column flex-md-row" v-if="
        current_plan.id != 15 &&
        current_plan.id != 401 &&
        current_plan.id != 402 &&
        current_plan.id != 403 &&
        current_plan.id != 404 &&
        current_plan.id != 405
      ">
        <div class="mx-auto me-md-auto ms-md-0 d-flex flex-column">
          <h3 class="text-white fw-bold text-center text-md-start">
            {{ Go_Super_Mind | multilingual }}
          </h3>
          <p class="text-white text-center text-md-start">
            {{
              Get_full_access_to_the_full_mode_and_other_features | multilingual
            }}
          </p>
          <router-link to="/pricing" class="mx-auto me-md-auto ms-md-0 mt-auto">
            <button class="btn btn-white w-100">
              {{ Upgrade_Now | multilingual }}
            </button>
          </router-link>
        </div>
        <div class="d-flex mt-5 mt-md-0">
          <img src="../assets/dashboard/dashboard-upgrade.png" alt="upgrade" class="nexcard-img" />
        </div>
      </div>

      <div class="nexcard grey-bg d-flex flex-column flex-md-row">
        <div class="mx-auto me-md-auto ms-md-0 d-flex flex-column">
          <h3 class="text-primary fw-bold text-center text-md-start">
            {{ Join_Our_Community | multilingual }}
          </h3>
          <p class="text-muted text-center text-md-start">
            {{
              Join_with_us_in_social_media_Get_all_latest_updates | multilingual
            }}
          </p>
          <div class="d-flex mt-auto socials mx-auto me-md-auto ms-md-0">
            <a href="https://my.linkedin.com/company/nexmind-ai" target="_blank" class="mx-3 ms-md-0">
              <img src="../assets/icons/icon-linkedin-blue.svg" alt="linkedin" style="height: 40px" />
            </a>
            <a href="https://www.quora.com/profile/NexMind-AI" target="_blank" class="mx-3">
              <img src="../assets/icons/icon-quora-blue.svg" alt="quora" style="height: 40px" />
            </a>
            <a href="https://www.reddit.com/user/Nexmind/" target="_blank" class="mx-3">
              <img src="../assets/icons/icon-reddit-blue.svg" alt="reddit" style="height: 40px" />
            </a>
          </div>
        </div>
        <div class="d-flex mt-5 mt-md-0">
          <img src="../assets/dashboard/dashboard-community.png" alt="upgrade" class="nexcard-img" />
        </div>
      </div>
      <transition name="fade" mode="out-in">
        <router-view :key="$route.path"> </router-view>
      </transition>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import AiContentGenerator from "@/store/AiContentGenerator.js";
import Automation from "@/store/Automation.js";
import Enterprise from "@/store/Enterprise.js";
import createProject from "@/Pages/Projects/createProject.vue";
export default {
  name: "Dashboard",
  components: { createProject },
  data() {
    return {
      n: 1,
      email: JSON.parse(localStorage.userInfo).email,
      permissions: localStorage.permissions,
      name: JSON.parse(localStorage.userInfo).name,
      seo_quota:
        JSON.parse(localStorage.userInfo).free_nex_automate_quota +
        JSON.parse(localStorage.userInfo).used_nex_automate_quota,
      content_quota: JSON.parse(localStorage.userInfo).nexwriter_quota,
      enterprise_quota:
        JSON.parse(localStorage.userInfo).free_indexability_quota +
        JSON.parse(localStorage.userInfo).used_indexability_quota,
      Ai_ContentGenerator_count: 0,
      ai_Automation_count: 0,
      enterprise_count: 0,
      project_count: JSON.parse(localStorage.nexrank_client),
      showAiSeo:
        JSON.parse(localStorage.userInfo).free_keyword_query_quota +
        JSON.parse(localStorage.userInfo).used_keyword_query_quota +
        JSON.parse(localStorage.userInfo).keywords_qouta +
        JSON.parse(localStorage.userInfo).free_nex_automate_quota +
        JSON.parse(localStorage.userInfo).used_nex_automate_quota +
        JSON.parse(localStorage.userInfo).free_content_planner_quota +
        JSON.parse(localStorage.userInfo).used_content_planner_quota +
        JSON.parse(localStorage.userInfo).used_indexability_quota +
        JSON.parse(localStorage.userInfo).free_indexability_quota,
      seo_caegories: [
        {
          title: "Keyword Discovery",
          icon: "icon-keyword-discovery",
          permission: "keyword_query.index",
          quota:
            JSON.parse(localStorage.userInfo).free_keyword_query_quota +
            JSON.parse(localStorage.userInfo).used_keyword_query_quota,
        },
        {
          title: "Keyword Tracker",
          icon: "icon-keyword-tracker",
          permission: "nexrank-clients.keywords.index",
          quota: JSON.parse(localStorage.userInfo).keywords_qouta,
        },
        {
          title: "AI SEO Automation",
          icon: "icon-ai-seo-automation",
          permission: "Seo-Automation.index",
          quota:
            JSON.parse(localStorage.userInfo).free_nex_automate_quota +
            JSON.parse(localStorage.userInfo).used_nex_automate_quota,
        },
        {
          title: "AI Content Planner",
          icon: "icon-ai-content-planner",
          permission: "Seo-Automation.index",
          quota:
            JSON.parse(localStorage.userInfo).free_content_planner_quota +
            JSON.parse(localStorage.userInfo).used_content_planner_quota,
        },
        {
          title: "CMS Editor",
          icon: "icon-cms-editor",
          permission: "nexrank-client.index",
          quota: JSON.parse(localStorage.userInfo).keywords_qouta,
        },
        {
          title: "Enterprise SEO",
          icon: "icon-seo-split-testing",
          permission: "indexability.index",
          quota:
            JSON.parse(localStorage.userInfo).used_indexability_quota +
            JSON.parse(localStorage.userInfo).free_indexability_quota,
        },
      ],
      show_seo_categories: [],

      Cases_blogwriting: [
        {
          title: "blog writing",
          desc: "Generate unique, SEO-optimized, long-form (up to 1,000 words) blog articles in just a few simple steps.",
          icon: "icon-blog-writing",
        },
      ],
      show_Cases_blogwriting: [],

      Cases_long: [
        {
          title: "blog idea and outline",
          desc: "Well-structured blog ideas and outlines for you to write a clear and cohesive blog post. ",
          icon: "icon-idea",
        },
        {
          title: "blog section writing",
          desc: "Make it easier for you to create section writing to improve the readability, clarity, and SEO of your content. ",
          icon: "icon-writing",
        },
        {
          title: "business idea pitching",
          desc: "Create a successful pitch to secure funding, build partnerships and momentum for your business. ",
          icon: "icon-business-idea-pitching",
        },
        {
          title: "selling copywriting",
          desc: "The best-performing copywriting that converts visitors into customers. ",
          icon: "icon-copywriting",
        },
        {
          title: "descriptive copywriting",
          desc: "Compelling description that effectively communicates the value of your product or service to potential customers.",
          icon: "icon-copywriting",
        },
        {
          title: "paragraphs",
          desc: "Entice your audience with well-structured paragraphs that are easy to read and effectively convey your message.",
          icon: "icon-paragraph",
        },
        {
          title: "keyword cluster",
          desc: "Get a keyword cluster related to your topic and generate paragraphs accordingly",
          icon: "icon-keywordcluster-blue",
        },
      ],
      show_Cases_long: [],

      Cases_short_Adv: [
        {
          title: "facebook ads",
          desc: "Make your Facebook ads stand out with compelling ad copy.  ",
          icon: "icon-facebook-outline",
        },
        {
          title: "linkedIn ads",
          desc: "Eye-catching LinkedIn ads that help you engage and persuade your readers to take action. ",
          icon: "icon-linkedin-outline",
        },
        {
          title: "google ad title",
          desc: "Create a strong ad title and entice potential customers to click on the ad. ",
          icon: "icon-google-outline",
        },
        {
          title: "google ad descriptions",
          desc: "An attractive and effective description that helps your ad stand out.",
          icon: "icon-google-outline",
        },
        {
          title: "google search ads",
          desc: "Maximize your ad's visibility and drive more targeted traffic to your website. ",
          icon: "icon-google-outline",
        },
      ],
      show_Cases_short_Adv: [],

      Cases_short_Post: [
        {
          title: "facebook captions",
          desc: "Make your Facebook posts stand out and draw in more engagement. ",
          icon: "icon-facebook-outline",
        },
        {
          title: "linkedIn posts",
          desc: "Create a LinkedIn post that inspires and informs, and watch as your trust and authority within your industry grows. ",
          icon: "icon-linkedin-outline",
        },
        {
          title: "youtube intros",
          desc: "Catchy intros that will grab the viewer's attention and establish your brand. ",
          icon: "icon-youtube-outline",
        },
        {
          title: "youtube descriptions",
          desc: "Rank your videos higher with influential descriptions.  ",
          icon: "icon-youtube-outline",
        },
        {
          title: "instagram captions",
          desc: "Create captivating Instagram captions that stop the scroll.",
          icon: "icon-instagram-outline",
        },
        {
          title: "tiktok captions",
          desc: "Create engaging and high-quality hook on your TikTok.",
          icon: "icon-tiktok-outline",
        },
        {
          title: "twitter posts",
          desc: "Use AI to produce current and pertinent tweets.",
          icon: "icon-twitter-outline",
        },
      ],
      show_Cases_short_Post: [],

      Cases_short_Prod: [
        {
          title: "product descriptions",
          desc: "Compelling product descriptions that will inform, persuade, and differentiate.  ",
          icon: "icon-product-description",
        },
        {
          title: "video description",
          desc: "Compelling product descriptions that will inform, persuade, and differentiate.  ",
          icon: "icon-video-description",
        },
        {
          title: "video idea",
          desc: "Compelling product descriptions that will inform, persuade, and differentiate.  ",
          icon: "icon-video-idea",
        },
      ],
      show_Cases_short_Prod: [],
      widget: [
        "Keyword Discovery",
        "Keyword Tracker",
        "AI SEO Automation",
        "AI Content Planner",
        "CMS Editor",
        "Enterprise SEO",
        "blog writing",
        "blog idea and outline",
        "blog section writing",
        "business idea pitching",
        "selling copywriting",
        "facebook ads",
        "linkedIn ads",
        "google ad title",
        "google ad descriptions",
        "google search ads",
        "facebook captions",
        "linkedIn posts",
        "youtube intros",
        "youtube descriptions",
        "product descriptions",
        "video description",
        "video idea",
      ],
      selected_categories: [],
      current_plan: [],

      //Translation words

      Welcome: "Welcome",
      Explore_with_Nexmind: "Explore with Nexmind",
      Create_Project: "Create Project",
      Complete_Tutorial: "Complete Tutorial",
      Watch: "Watch",
      Create_your_First_Project: "Create your First Project",
      Start: "Start",
      Start_Your_First_Automation: "Start Your First Automation",
      Create_Your_First_Content: "Create Your First Content",
      Add_Your_First_Page: "Add Your First Page",
      What_would_you_like_to_do_today: "What would you like to do today?",
      AI_SEO_1_credit_per_Campaign: "AI SEO (1 credit per Campaign)",
      View_All_Campaigns: "View All Campaigns",
      Blog_Writing_4_credits: "Blog Writing (4 credits)",
      Freestyle_4_credits: "Freestyle (4 credits)",
      View_Others: "View Others",
      Advertisement_1_credit: "Advertisement (1 credit)",
      Post_and_captions_idea_1_credit: "Post and Captions Idea (1 credit)",
      E_commerce_1_credit: "E-commerce (1 credit)",
      Go_Super_Mind: "Go Super Mind!",
      Get_full_access_to_the_full_mode_and_other_features:
        "Get full access to the full mode and other features",
      Upgrade_Now: "Upgrade Now",
      Join_Our_Community: "Join Our Community",
      Join_with_us_in_social_media_Get_all_latest_updates:
        "Join with us in social media, Get all latest updates",
    };
  },

  methods: {
    viewWidget() {
      console.log("OPEN ")
      localStorage.setItem("openWidget", "true");
    },

    showAllCampaigns() {
      this.$router.push({
        name: "feature_pagination_table",
        params: {
          id: this.$route.params.id,
          feature: "content_image",
        },
      });
    },
    watchTutorial() {
      this.$router.push({
        name: "tutorials",
      });
    },
    OpenPage(title) {
      console.log(title);

      if (title == "Keyword Discovery") {
        this.$router.push({
          name: "feature_pagination_table",
          params: {
            feature: "keyword_discovery",
          },
        });
      } else if (title == "Keyword Tracker") {
        this.$router.push({
          name: "project_pagination_table",
          params: {
            feature: "keyword_tracker",
            params: {
              feature: "meta_cms",
            },
          },
        });
      } else if (title == "AI SEO Automation") {
        this.$router.push({
          name: "feature_pagination_table",
          params: {
            feature: "ai_seo_automation",
          },
        });
      } else if (title == "AI Content Planner") {
        this.$router.push({
          name: "feature_pagination_table",
          params: {
            feature: "ai_content_planner",
          },
        });
      } else if (title == "CMS Editor") {
        this.$router.push({
          name: "project_pagination_table",
          params: {
            feature: "meta_cms",
          },
        });
      } else if (title == "Enterprise SEO") {
        this.$router.push({
          name: "feature_pagination_table",
          params: {
            feature: "enterprise_seo",
          },
        });
      }
    },
    addContent(title) {
      this.$router.push({
        name: "content_image_add_fields",
        params: { category: title, step: "topic_details" },
      });
    },
    choose_category(category) {
      this.$router.push({
        name: "content_image_add",
        params: {
          category: category,
        },
      });
    },

    localStoreHandler(e) {
      //console.log(`👉 localStorage.set('${e.key}', '${e.value}') updated`);
      if (e.key == "dashboardWidgets") {
        this.selected_categories = JSON.parse(e.value);
      }
    },

    rearrangeCateegories() {
      this.show_seo_categories = [];
      this.selected_categories.forEach((element_widget) => {
        this.seo_caegories.forEach((element) => {
          if (element_widget == element.title) {
            this.show_seo_categories.push(element);
          }
        });
      });

      this.show_Cases_blogwriting = [];
      this.selected_categories.forEach((element_widget) => {
        this.Cases_blogwriting.forEach((element) => {
          if (element_widget == element.title) {
            this.show_Cases_blogwriting.push(element);
          }
        });
      });

      this.show_Cases_long = [];
      this.selected_categories.forEach((element_widget) => {
        this.Cases_long.forEach((element) => {
          if (element_widget == element.title) {
            this.show_Cases_long.push(element);
          }
        });
      });

      this.show_Cases_short_Adv = [];
      this.selected_categories.forEach((element_widget) => {
        this.Cases_short_Adv.forEach((element) => {
          if (element_widget == element.title) {
            this.show_Cases_short_Adv.push(element);
          }
        });
      });

      this.show_Cases_short_Post = [];
      this.selected_categories.forEach((element_widget) => {
        this.Cases_short_Post.forEach((element) => {
          if (element_widget == element.title) {
            this.show_Cases_short_Post.push(element);
          }
        });
      });

      this.show_Cases_short_Prod = [];
      this.selected_categories.forEach((element_widget) => {
        this.Cases_short_Prod.forEach((element) => {
          if (element_widget == element.title) {
            this.show_Cases_short_Prod.push(element);
          }
        });
      });
    },

    // $('#example').tooltip(options)
  },
  watch: {
    selected_categories() {
      // console.log("watch", this.selected_categories);
      this.rearrangeCateegories();
    },
  },
  mounted() {
    // let callback = (val, oldVal, uri) => {
    //   console.log("localStorage change", val);
    //   console.log(ls.get("foo"));
    // };
    // ls.set("foo", "boo");
    // ls.on("foo", callback); //watch change foo key and triggered callback

    const localStore = localStorage.setItem;

    localStorage.setItem = function (key, value) {
      const event = new Event("localUpdated");
      event.key = key;
      event.value = value;

      document.dispatchEvent(event);
      localStore.apply(this, arguments);
    };

    document.addEventListener("localUpdated", this.localStoreHandler, false);
    if (
      localStorage.dashboardWidgets == undefined ||
      localStorage.dashboardWidgets == "undefined" ||
      JSON.parse(localStorage.dashboardWidgets).length == 0
    ) {
      localStorage.setItem("dashboardWidgets", JSON.stringify(this.widget));
      this.show_seo_categories = this.seo_caegories;
    } else {
      this.selected_categories = JSON.parse(localStorage.dashboardWidgets);
      this.rearrangeCateegories();
    }
  },
  created() {
    if (JSON.parse(localStorage.userInfo).type == "agent_client") {
      this.$router.push({ name: "agent_client" });
    } else if (
      JSON.parse(localStorage.userInfo).not_have_plan_yet == "1" ||
      JSON.parse(localStorage.userInfo).not_have_plan_yet == 1
    ) {
      this.$router.push({ name: "pricing_dashboard" });
    } else {
      if (this.permissions.includes("Nexwriter.index")) {
        AiContentGenerator.fetchContentCampaigns(`?page=1`)
          .then((response) => {
            this.Ai_ContentGenerator_count = response.data.data.total;
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (this.permissions.includes("Seo-Automation.index")) {
        Automation.fetchSeoAutomationParams(`?page=1`)
          .then((response) => {
            this.ai_Automation_count = response.data.data.total;
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (this.permissions.includes("indexability.index")) {
        Enterprise.fetchDatasParam(`?page=1`)
          .then((response) => {
            this.enterprise_count = response.data.data.total;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nexcard {
  background-color: #fff;
  border-radius: 10px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px; */
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 40px; */
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
  padding: 2rem;
}

.grey-bg {
  background-color: #eeeeee;
}

.no-bs {
  box-shadow: none !important;
}

.blue-bg {
  background-image: linear-gradient(to right, #1565c0, #1565c0);
}

.grey-bg {
  background-color: #eeeeee;
}

.btn-white {
  border-color: #ffffff;
  background-color: #ffffff;
  color: #1465c1;
  font-weight: bold;
}

.btn-white:hover,
.btn-white:active {
  border-color: #e9e9e9;
  background-color: #e9e9e9;
}

.nexcard-body .material-icons-outlined {
  color: #2c8ee1;
  font-size: 2.5rem;
}

.text-green {
  color: #4dae50 !important;
}

.hr-line {
  border: 1px solid #686868;
  width: 60px;
  margin: 1.5rem 0 1rem;
}

.btn-tooltip .material-icons-outlined {
  margin-top: -1rem;
  font-size: 1.2rem;
  color: #7f7f7f !important;
}

.text-darkgrey {
  color: #474747;
  margin-left: 0.8rem;
}

.dashboard__apps {
  padding: 0;
}

.dashboard__apps .row .col-size .dashboard__app {
  aspect-ratio: 1.1/1;
  padding: 0.8rem;
  text-align: center;
  min-height: 120px;
  width: 140px;
  margin: 1rem auto;
  border-radius: 10px;
  background-color: #fff;
  transition: 0.2s ease-out;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
}

.dashboard__apps .row .col-size .dashboard__app:hover {
  /* border: 1px solid red; */
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
  transition: 0.5 ease-in;
  cursor: pointer;
  user-select: none;
  background-color: #edf2fb;
  transition: 0.2s ease-out;
}

.dashboard__apps .row .col-size .dashboard__app:hover img {
  scale: 1.05;
  transition: all 0.2s ease-in-out;
}

/* .dashboard__apps .row .col-size .dashboard__app:active {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
} */
.dashboard__apps .row .col-size span {
  margin: auto;
}

.dashboard__apps .row .col-size .dashboard__app img {
  width: 50px;
  aspect-ratio: 1/1;
  object-fit: contain;
  margin: auto;
  padding-top: 0.2rem;
}

.dashboard__apps .row .col-size .text {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.2rem;
}

.col-size {
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
  width: 20% !important;
  float: left;
}

.socials img {
  filter: brightness(100%);
  transition: 0.3s ease-out;
}

.socials img:hover {
  filter: brightness(85%);
  transition: 0.3s ease-out;
}

.btn-editDashboard {
  color: #2c8ee1 !important;
  font-size: 30px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 6px;
  padding: 0.2rem;
}

.btn-viewlist {
  color: #474747 !important;
  font-size: 25px !important;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
  border-radius: 6px;
  padding: 0.2rem;
}

.content_image .material-icons-outlined {
  color: #2c8ee1;
  font-size: 2rem;
}

.fw-600 {
  font-weight: 600;
}

.nexcard-img {
  max-height: 150px;
  object-fit: contain;
}

@media only screen and (max-width: 768px) {
  .nexcard {
    padding: 1rem !important;
  }

  .hr-line {
    width: 100% !important;
    margin: 1rem 0 1rem;
  }

  .nexcard-img {
    max-height: 100%;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    object-fit: contain;
  }

  .col-size {
    min-height: 1px;
    padding-right: 5px;
    padding-left: 5px;
    width: 50% !important;
    float: left;
  }
}
</style>
